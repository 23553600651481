import { MenuItem } from '@mui/material';

export const emailPattern = /^([\w+&*-]{1,64}(?:\.[\w+&*-]{1,32}){0,64})@(?:[a-z\d-]{0,253}\.){1,251}[a-z]{2,63}$/i;

export const checkObjectKeys = (object: Record<string, any>, value: string): boolean =>
  Object.keys(object).some((key) => key.includes(value));

export function truncateNumber(numToTruncate: number, decimalPlaces = 100): number {
  // eslint-disable-next-line security/detect-non-literal-regexp
  const re = new RegExp(`(\\d+\\.\\d{${decimalPlaces}})(\\d)`);
  const m = numToTruncate.toString().match(re);
  return m ? parseFloat(m[1]) : numToTruncate.valueOf();
}

interface Option {
  [key: string]: any;
}

export function createMenuItems(objects: Option[] = [], key = 'id', value = 'value', label = 'label') {
  return objects.map((option) => (
    <MenuItem key={option[key]} value={option[value]}>
      {option[label]}
    </MenuItem>
  ));
}