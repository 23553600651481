import { addDays, format, formatISO, getMonth, getQuarter, intervalToDuration, isDate, isSameQuarter, isValid } from 'date-fns';
import type { IntlShape } from 'react-intl';

interface DateObject {
   [key: string]: any;
}

export function convertDatesToUTC(obj: DateObject): DateObject {
   return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key, isValid(value) && isDate(value) ? formatISO(value, { representation: 'date' }) : value])
   );
}

export function getHalfYear(date: Date): number {
   return getQuarter(date) <= 2 ? 1 : 2;
}

export function friendlyFormatDateTimeRange(from: Date, to: Date): string | undefined {
   const duration = intervalToDuration({ start: from, end: addDays(to, 1) });
   if (!duration) {
      return undefined;
   }

   const [unevenPart = '', unevenValue] = Object.entries(duration).find(([, value]) => value !== 0) || [];

   if (unevenPart === 'years' && unevenValue === 1) {
      return format(to, 'yyyy');
   }

   if (!['years', 'months'].includes(unevenPart)) {
      return undefined;
   }

   if (Object.values(duration).filter((value) => value === 0).length !== Object.values(duration).length - 1) {
      return undefined;
   }

   if (unevenPart === 'months') {
      switch (unevenValue) {
         case 1:
            return format(to, 'MM yyyy');
         case 3:
            return isSameQuarter(from, to) ? format(to, 'QQQ yyyy') : undefined;
         case 6:
            return [0, 6].includes(getMonth(from)) ? `H${getHalfYear(from)} ${from.getFullYear()}` : undefined;
         default:
            return undefined;
      }
   }

   return undefined;
}

interface RelativeTimeInterval {
   value: number;
   unit: Intl.RelativeTimeFormatUnit;
}

function getRelativeTimeInterval(date: Date): RelativeTimeInterval {
   const interval = intervalToDuration({
      start: date,
      end: new Date(),
   });

   let value: number;
   let unit: Intl.RelativeTimeFormatUnit;

   if (interval.years && interval.years >= 1) {
      unit = 'year';
      value = interval.years;
   } else if (interval.years && interval.years < 1 && interval.months && interval.months > 0) {
      value = interval.months;
      unit = 'month';
   } /* else if (interval.months && interval.months < 1 && (interval?.weeks ?? 0) > 0) {
      value = interval?.weeks ?? 0;
      unit = 'week';
   } */ else if (interval.months && interval.months < 1 && interval.days && interval.days > 0) {
      value = interval.days;
      unit = 'day';
   } else if (interval.days && interval.days < 1 && interval.hours && interval.hours > 0) {
      value = interval.hours;
      unit = 'hour';
   } else if (interval.hours && interval.hours < 1 && interval.minutes && interval.minutes > 0) {
      value = interval.minutes;
      unit = 'minute';
   } else {
      value = interval.seconds || 0;
      unit = 'second';
   }

   return {
      value,
      unit,
   };
}

export function getRelativeTime(intl: IntlShape, date: Date, options: Intl.RelativeTimeFormatOptions = { numeric: 'auto' }): string {
   const interval = getRelativeTimeInterval(date);

   return intl.formatRelativeTime(-interval.value, interval.unit, { style: 'narrow', ...options });
}
